.container.page-cookie {
    max-width: 100%;
    padding-top: 2rem;
}

h1, h2, h3, h4 {
    font-weight: 700;
}

h1 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.25rem;
    line-height: 2.5rem
}

h2 h3 {
    line-height: 2rem;
    font-size: 1.5rem;
}

h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1.25rem;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

p {
    white-space: pre-line;
    font-size: 1.12rem;
    font-weight: 400;
}

p strong {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

@media (min-width: 768px) {
    .container {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 20rem;
    }
}

@media (max-width: 768px) {
    .container.page-cookie {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 25rem;
    }
}