.LoginContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginForm {
  width: 400px;
  max-width: 95%;
}
.Button {
  width: 100%;
}
