.ant-table th {
  white-space: nowrap;
}

lyf-wc-cookie .button {
  min-width: 5rem !important;
  height: 2rem !important;
  border-radius: 0 !important;
  font-size: initial !important;
}
