.PurchasesTable-Row-Animated {
  animation: blinkbg 5s;
}

@keyframes blinkbg {
  from {
    background: none;
  }

  15% {
    background: #69c0ff;
  }

  25% {
    background: #69c0ff;
  }

  to {
    background: none;
  }
}
