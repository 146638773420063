body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media print {
  .ant-layout {
    height: auto !important;
  }
  .ant-layout-header {
    display: none;
  }
  .ant-layout-sider {
    display: none;
  }
  .ant-divider {
    margin-bottom: 0px !important;
  }
  td {
    padding: 0px !important;
  }
}
