.InviteContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.InviteForm {
  width: 600px;
  max-width: 95%;
}
.Button {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .InviteForm {
    width: 95%;
  }
}
